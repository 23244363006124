import request from '@/util/request';

export async function postFile(file, type) {
    return request({
        url: `/bqsb/util/upload/${type}`,
        method: 'post',
        data: file,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export async function getSigleArea(areaParentId) {
    const data = await request({
        url: `/bqsb/util/sigleArea/${areaParentId}`,
        method: 'get'
    });
    return data.data;
}

export async function getArea() {
    const data = await request({
        url: `/bqsb/util/area`,
        method: 'get'
    });
    return data.data;
}

export async function postUniFile(file) {
    return request({
        url: `/bqsb/util/uniUpload`,
        method: 'post',
        data: file,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export async function postFileJson(type, data) {
    return request({
        url: `/bqsb/util/upload/bqjy/${type}/json`,
        method: 'post',
        data,
    });
}
