<template>
  <div class="container min-height flex-column justify-content-spaceBetween align-items-center">
    <div class="width-100 flex-column justify-content-start align-items-center">
      <top :active="3"></top>
      <div class="container-box copyrightRegistration flex-column justify-content-start align-items-center">
        <div class="width-100 flex-row justify-content-spaceBetween align-items-center border-b">
          <p class="fs-super-big black fw-mid">企业注册</p>
        </div>
        <div class="width-60 form-box">
          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="企业名称" prop="ownerName">
              <el-input v-model="form.ownerName" placeholder="请输入企业名称"></el-input>
            </el-form-item>
            <el-form-item label="统一社会信用代码" prop="cardNo">
              <el-input v-model="form.cardNo" placeholder="请输入统一社会信用代码"></el-input>
            </el-form-item>
            <el-form-item label="法人姓名" prop="legalPersonName">
              <el-input v-model="form.legalPersonName" placeholder="请输入法人姓名"></el-input>
            </el-form-item>
            <el-form-item label="联系方式" prop="mobile">
                <el-input v-model="form.mobile" placeholder="请输入联系方式"></el-input>
              </el-form-item>
            <el-form-item>
              <el-button type="success" size="mini" @click="getCode">获取短信</el-button>
            </el-form-item>
            <el-form-item label="短信验证码" prop="code">
              <el-input v-model="form.code" placeholder="请输入验证码"></el-input>
            </el-form-item>
            <el-form-item prop="regionsNo" label="户籍所在地">
              <el-cascader v-model="form.regionsNo" :props="regionsNoOption" placeholder="请选择户籍所在地" />
            </el-form-item>
            <el-form-item
              label="营业执照"
              prop="copyrightImage"
              :rules="{
                required: true,
                message: '请上传版权图片'
              }"
            >
              <!-- <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="form.copyrightImage" :src="form.copyrightImage" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload> -->

              <el-upload
                :limit="5"
                class="avatar-uploader"
                :before-upload="beforeImageUpload"
                :on-success="onCopyrightImageSuccess"
                :on-remove="onCopyrightImageRemove"
                :file-list="form.copyrightImages"
                :action="`${PROJECT_BASE_URL}/bqsb/util/upload/1`"
                list-type="picture-card"
              >
                <i class="el-icon-plus" />
              </el-upload>
            </el-form-item>
            <el-checkbox v-model="checked" size="medium">本人承诺以上信息均真实有效</el-checkbox>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import top from '../components/top';
import bottom from '../components/footer';
import { getDict } from '@/api/dict';
// import { postCompanyUserInfo, registrationInfo, putCompanyUserInfo } from '@/api/tranCompanyUserInfo';
import { postCompanyUserInfo, registrationInfo, putCompanyUserInfo, getCompanyMobileCode } from '@/api/tranCompanyUserInfo';
// import { getMyself } from '@/api/copyright';
import { getSigleArea } from '@/api/util';
import * as _ from 'lodash';
// import { getTextByValue } from '@/util/helper';

// TODO 页面首次进来是，输入框显示异常

export default {
  name: 'copyrightRegistration',
  components: {
    top,
    bottom
  },
  data() {
    return {
      checked: false,
      dialogVisible: false,
      search: '',
      form: {
        ownerName: '', // 版权名称
        cardNo: '', // 证书编号
        mobile: null, // 联系方式
        code: '', // 短信验证码
        legalPersonName: '', // 法人姓名
        regionsNo: '',
        copyrightImages: [],
        copyrightImage: '' // 版权图片
      },
      rules: {
        ownerName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        cardNo: [{ required: true, message: '请输入统一社会信用代码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
        legalPersonName: [{ required: true, message: '请输入法人姓名', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        regionsNo: [{ required: true, message: '请输入户籍所在地', trigger: 'blur' }],
        copyrightImage: [{ required: true, message: '请上传营业执照' }]
      },
      list: [],
      bqsbCopyrightTypeDist: [],
      tranCopyrightUsageDist: [],
      tranAuthorizationTypeDist: [],
      tranAuthorizationTimeDist: [],
      tranAuthorizationScopeDist: [],
      tranExistCopyrightDist: [],
      tranPublicAvailableDist: [],
      PROJECT_BASE_URL: process.env.VUE_APP_SERVE_PATH,
      params: {
        params: {
          contactMsg: 'true'
        }
      },
      myselfParams: {},
      regionsNoOption: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const { level, value } = node;
          let area = [];
          if (Number(level) === 0) {
            area = await getSigleArea(0);
          } else {
            area = await getSigleArea(value);
          }
          resolve(
            area.map((row) => {
              return {
                value: row.value,
                label: row.text,
                leaf: level >= 2
              };
            })
          );
        }
      }
    };
  },
  async created() {
    await this.setDist();
    // await this.getMyselfCopyright();
    if (this.$route.query.id) {
      this.updateForm(this.$route.query.id);
    }
  },
  methods: {
    async setDist() {
      return Promise.all([
        getDict('bqsb_copyright_type'),
        getDict('tran_copyright_usage'),
        getDict('tran_authorization_type'),
        getDict('tran_authorization_time'),
        getDict('tran_authorization_scope'),
        getDict('tran_exist_copyright'),
        getDict('tran_public_available')
      ]).then((rows) => {
        this.bqsbCopyrightTypeDist = rows[0];
        this.tranCopyrightUsageDist = rows[1];
        this.tranAuthorizationTypeDist = rows[2];
        this.tranAuthorizationTimeDist = rows[3];
        this.tranAuthorizationScopeDist = rows[4];
        this.tranExistCopyrightDist = rows[5];
        this.tranPublicAvailableDist = rows[6];
      });
    },
    async onSubmit() {
      if (!this.checked) {
        const that = this;
        this.$alert('请勾选本人承诺！', '提示', {
          confirmButtonText: '确定',
          callback: () => {
            that.checked = true;
          }
        });
        return;
      }
      const dataForm = this.$refs.form;
      await dataForm.validate().then(
        async () => {
          if (this.form.id) {
            if (this.form.copyrightCardImages) {
              this.form.copyrightCardImage = this.form.copyrightCardImages
                .map((row) => {
                  if (row.response) {
                    return row.response.data.targetFileUrl;
                  } else {
                    return row.url.substring(row.url.indexOf(this.PROJECT_BASE_URL) + this.PROJECT_BASE_URL.length);
                  }
                })
                .join(',');
            }
            this.form.copyrightImage = this.form.copyrightImages
              .map((row) => {
                if (row.response) {
                  return row.response.data.targetFileUrl;
                } else {
                  return row.url.substring(row.url.indexOf(this.PROJECT_BASE_URL) + this.PROJECT_BASE_URL.length);
                }
              })
              .join(',');
            // console.log(this.form);
            await putCompanyUserInfo(this.form);
          } else {
            // console.log('this.form.copyrightImages:', this.form.copyrightImages);
            this.form.cardImage = this.form.copyrightImages[0]?.response.data.targetFileUrl;
            this.form.copyrightFilesId = this.form.copyrightImages[0]?.response.data.id;
            this.form.regionsNo = this.form.regionsNo[2];
            // console.log('form:', this.form);
            await postCompanyUserInfo(this.form);
          }
          this.$message.success('注册成功，请登录');
          this.$router.go(-1);
        },
        () => {
          // TODO
        }
      );
    },
    // onSampleSuccess(res, file) {
    //   this.imageUrl = URL.createObjectURL(file.raw);
    // },
    onCopyrightImageSuccess(res, file, fileList) {
      this.form.copyrightImages = fileList;
      this.form.copyrightImage = fileList;
    },
    onCopyrightImageRemove(file, fileList) {
      this.form.copyrightImages = fileList;
      this.form.copyrightImage = fileList;
    },
    onCopyrightCardImageSuccess(res, file, fileList) {
      this.form.copyrightCardImages = fileList;
      this.form.copyrightCardImage = fileList;
    },
    onCopyrightCardImageRemove(file, fileList) {
      this.form.copyrightCardImages = fileList;
      this.form.copyrightCardImage = fileList;
    },
    beforeImageUpload(file) {
      const fileType = ['image/png', 'image/jpeg', 'image/jpg', 'image/pjpeg', 'image/x-png'];
      console.log('file.type:', file);
      if (/#/.test(file.name)) {
        this.$message.error('图片名称不可包含#');
      }
      const fileTypeCheck = !fileType.includes(file.type);
      if (!fileType.includes(file.type)) {
        this.$message.error('上传图片只能是 JPG/JPEG/PNG 格式!');
      }
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 3MB!');
      }
      return isLt2M && !fileTypeCheck && !/#/.test(file.name);
    },
    // async getMyselfCopyright() {
    //   if (this.search) this.myselfParams.title = this.search;
    //   const myselfCopyright = await getMyself(this.myselfParams);
    //   this.list = myselfCopyright.data.map((row) => {
    //     let realUrl = null;
    //     if (row.copyrightFile[0].targetFileUrl.startsWith('http')) {
    //       realUrl = row.copyrightFile[0].targetFileUrl;
    //     } else {
    //       realUrl = this.PROJECT_BASE_URL + row.copyrightFile[0].targetFileUrl;
    //     }
    //     if (/.pdf/.test(realUrl)) realUrl = this.PROJECT_BASE_URL + `/profile/bqsb/2023/05/04/pdf.jpg`;
    //     if (/.dwg/.test(realUrl)) realUrl = this.PROJECT_BASE_URL + `/profile/bqsb/2024/03/25/dwg.jpg`;
    //     console.log('targetFileUrl:', realUrl);
    //     return {
    //       id: row.copyrightInfo.id,
    //       targetFileUrl: realUrl,
    //       copyrightImage: row.copyrightFile[0],
    //       title: row.copyrightInfo.title,
    //       ownerShipWayName: row.copyrightInfo.ownerShipWayName,
    //       copyrightNo: row.copyrightInfo.copyrightNo,
    //       copyRightTypeName: row.copyrightInfo.copyRightTypeName,
    //       copyrightTypeOn: row.copyrightInfo.copyRightTypeNo,
    //       ownerName: row.ownersInfo[0].ownerName,
    //       instruction: row.copyrightDetailInfo.instruction
    //     };
    //   });
    // },
    async addCopyright() {
      this.dialogVisible = true;
    },
    addClick(item) {
      this.dialogVisible = false;
      this.form.ownerName = item.ownerName;
      this.form.copyrightOwnerName = item.ownerName;
      this.form.copyrightNo = item.copyrightNo.substring(4);
      this.form.copyrightTypeOn = item.copyrightTypeOn;
      this.form.existCopyright = 1;
      this.form.instruction = item.instruction;
    },
    async updateForm(id) {
      const info = await registrationInfo(id, this.params);
      this.form = {
        ..._.pick(info.data, [
          'id',
          'title',
          'cardNo',
          'mobile',
          'legalPersonName',
          'regionsNo'
        ]),
        copyrightImages: info.data.copyrightImage.split(',').map((url) => {
          return {
            url: `${this.PROJECT_BASE_URL}${url}`
          };
        }),
        copyrightCardImages:
          info.data.copyrightCardImage && info.data.copyrightCardImage.length > 5
            ? info.data.copyrightCardImage?.split(',').map((url) => {
                return {
                  url: `${this.PROJECT_BASE_URL}${url}`
                };
              })
            : null,
        copyrightNo: info.data.copyrightNo
          ? info.data.copyrightNo.substring(4).startsWith('11')
            ? info.data.copyrightNo.substring(4)
            : info.data.copyrightNo.substring(5)
          : '',
        copyrightUsage: Number(info.data.copyrightUsage),
        copyrightTypeOn: Number(info.data.copyrightTypeOn),
        publicAvailable: Number(info.data.publicAvailable),
        authorizationType: Number(info.data.authorizationType),
        authorizationScope: Number(info.data.authorizationScope) ? Number(info.data.authorizationScope) : null,
        authorizationTime: Number(info.data.authorizationTime),
        copyrightPrizeType: info.data.copyrightPrizeType ? String(info.data.copyrightPrizeType) : '0'
      };
    },
    copyrightClick() {
      // this.getMyselfCopyright();
    },
    getCode() {
      console.log('2131111111');
      if (!this.form.mobile) {
        this.$message.error('请填写联系方式');
        return ;
      }
      getCompanyMobileCode(this.form.mobile);
      this.$message.success('发送成功！');
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background: #f3f9ff;
  .container-box {
    margin: 40px 0;
    padding: 30px;
    border-radius: 10px;
    background: white;
    .icon-img {
      width: 48px;
      margin-left: 20px;
    }
    .form-box {
      padding: 60px 0;
    }
  }
  .copyrightRegistration {
    .existCopyright .el-form-item__label {
      line-height: 1.5rem;
    }
    .search-popup {
      height: 600px;
      padding: 40px;
      .search {
        border: 1px solid #dcdfe6;
        border-radius: 50px;
        margin-bottom: 40px;
        /deep/.el-input__inner {
          border: none !important;
          border-radius: 50px;
        }
      }
      .table-title {
        background: #f7f9fa;
        ul {
          margin: 20px 0;
        }
        li {
          padding: 10px 10px;
          font-size: 18px;
          text-align: center;
        }
        li:first-child {
          text-align: left;
        }
      }
      .table-main {
        height: 400px;
        overflow-y: scroll;
        .image {
          width: 25%;
          height: 60px;
        }
        ul {
          margin: 20px 0;
        }
        li {
          text-align: center;
        }
        li:first-child {
          text-align: left;
        }
      }
    }
  }
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form-item__label,
.el-input__inner,
.el-radio__label,
.el-textarea__inner,
.el-form-item__error {
  font-size: 18px;
}
.copyrightRegistration .el-input__inner {
  border: 1px solid #dcdfe6 !important;
  height: 3rem;
}
.copyrightRegistration .el-form-item {
  margin-bottom: 60px;
}

.copyrightRegistration .el-form-item__content {
  margin-left: 200px !important;
}

.copyrightRegistration .el-radio__input {
  opacity: 0 !important;
}
.copyrightRegistration .el-radio__input {
  opacity: 0 !important;
}
.copyrightRegistration .el-radio {
  padding-left: 0 !important;
  padding-right: 25px !important;
  margin-right: 10px !important;
}
.copyrightRegistration .el-checkbox {
  margin: 0 0 60px;
}
.copyrightRegistration .el-checkbox__label {
  font-size: 16px;
}
.copyrightRegistration .el-button--primary {
  padding: 20px 80px;
}
.copyrightRegistration .el-button--primary span {
  font-size: 20px;
}
.copyrightRegistration .el-checkbox {
  line-height: 1.5rem;
}
.copyrightRegistration .el-checkbox__inner {
  width: 1.5rem !important;
  height: 1.5rem !important;
}
.copyrightRegistration .el-checkbox__label {
  font-size: 18px !important;
}
.copyrightRegistration .el-checkbox__inner::after {
  width: 0.4rem !important;
  border-width: 0.15rem;
  top: 0.3rem !important;
}
.copyrightRegistration .el-form-item__label {
  width: 200px;
}
.copyrightRegistration .el-input__inner::placeholder {
  color: #999999 !important;
}
::deep .copyrightRegistration .el-select {
  width: 100% !important;
}
</style>
